.baas-banner {
  background-image: url("../assets/baas-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.baas-banner-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.baas-banner-img1 {
  /* width: 100px;
    position: relative; */
  position: relative;
  /* top: -10%; */
}

.baas-banner-img2 {
  /* width: 100px;
    position: relative; */
  position: absolute;

  /* top: -159%; */
  width: 76%;
  left: 37%;
}

.btn-get-started {
  width: 27%;
}

.gap-10 {
  gap: 10px;
}

.square {
  background: url("../assets/rectangle.svg");
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain; /* Or cover */
  background-position: center;
  position: relative;
  text-wrap: wrap;
  margin: 0 30px;
}

.circle {
  position: absolute;
  top: 6%;
  left: 36%;
}

.square p {
  font-size: 0.7rem !important;
}

.square h6 {
  width: 200px;
}

.square div {
  margin-top: 39%;
  text-align: center;
  padding: 10px;
}

.triangle {
  position: absolute;
  top: 73%;
  left: 77%;
}

.row2 {
  margin-top: -92px;
  margin-left: 145px;
}

.baas-bg {
  background: url("../assets/baas-bg.svg");
  width: 100%;
}

.font-smaller {
  font-size: 1rem;
}

.company-card > * {
  padding: 8px;
}

.service-table > tr > td {
  padding: 15px;
}
.service-table > tr > td:first-child {
  padding: 15px 40px;
}
.service-table > tr > td:last-child {
  padding: 15px 40px;
}

.light-pink-bg {
  background-color: rgb(236, 192, 192);
}

.baas-bottom-banner {
  background: url("../assets/baas-bottom-bg.svg");
  background-color: black;
  height: 15em;
  margin-top: 50px !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.faq-banner {
  background: url("../assets/baas-faq-banner.webp");
  background-color: black;
}

.accordion-container {
  margin: 0 auto;
}

.baas-solution .nav-tabs {
  border-bottom: 0px !important;
  margin-bottom: 10px;
}
.baas-solution .nav-link{
  border: 2px solid gray;
  border-radius: 5px !important;
}
.baas-solution .nav-link.active {
  background: black !important;
  /* margin-bottom: -2px !important; */
  border: 2px solid #e4c221 !important;
  /* border-bottom: 5px black !important; */
}
.baas-solution .nav-link:hover {
  background: black !important;
  /* margin-bottom: -2px !important; */
  border: 2px solid #e4c221 !important;
  /* border-bottom: 5px black !important; */
}
.custom-card-container .container:nth-child(2) {
  margin-top: 50px;
}
.custom-design-card .icon-img {
  width: 50px;
}

.service-table td img {
  margin: auto;
}
.baas-solution .nav-tabs {
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 6px;
  justify-content: center;
}
.baas-solution .nav-tabs .nav-link {
  width: max-content;
  margin-right: 5px;
  text-wrap: nowrap;
  font-size: 14px;
}

.baas-solution .nav-tabs::-webkit-scrollbar{
    height: 3px;
  }
  .baas-solution .nav-tabs::-webkit-scrollbar-thumb{
    background: #5c5c5c !important;
    border-radius: 4px;
  }
  .baas-solution .nav-tabs::-webkit-scrollbar-track{
    background: transparent !important;
  }

  .outer-wrapper-circle {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    display: grid !important;
    place-items: center;
    padding: 0 !important;
    min-width: 50px !important;
  }

  .service-table tr .special-child{
    display: none !important;
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /* Apply the animation to the element */
  .arrow-right-icon {
    animation: fade 2s infinite;
  }

  .info-div {
    width: 65%;
    margin: auto;
  }

  .rotated-rects .custom-design-card:nth-child(n+5) .info-div{
    margin-top: -25px !important;
  }
  .rotated-rects .custom-design-card:nth-child(n+5) img:nth-child(1){
    margin-top: -15px !important;
  }

  
@media only screen and (max-width: 992px) {
  .service-table td {
    min-width: 250px !important;
  }
  .baas-solution .nav-tabs::-webkit-scrollbar {
    height: 3px;
  }
  .baas-solution .nav-tabs::-webkit-scrollbar-thumb {
    background: rgb(89, 89, 89);
  }
  .baas-solution .nav-tabs::-webkit-scrollbar-track {
    background: #000;
  }
  .baas-solution .nav-tabs .nav-link {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 1530px) {
  .custom-design-card {
    margin-top: -35px;
  }
  .t-top {
    right: 28px;
    bottom: 60px;
  }
  .t-bottom {
    top: -5px;
    right: 30px;
  }
  .custom-card-container .container:nth-child(2) {
    margin-top: 90px;
  }
}
@media only screen and (max-width: 1400px) {
  .baas-solution .nav-tabs .nav-link {
    font-size: 12px;
  }
  .baas-banner {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .baas-banner .lines-img {
    left: 40%;
  }
  .custom-design-card {
    margin-top: -35px;
  }
  .t-top {
    right: 25px;
    bottom: 45px;
  }
  .t-bottom {
    top: -5px;
    right: 22px;
  }
  .custom-design-card .icon-img {
    width: 40px;
  }
  .info-div {
    width: 60%;
    margin: auto;
  }
  .rotated-rects .custom-design-card:nth-child(n+1) .info-div{
    margin-top: 10px !important;
  }
  .rotated-rects .custom-design-card:nth-child(n+5) .info-div{
    margin-top: -18px !important;
  }
  .rotated-rects .custom-design-card:nth-child(n+5) img:nth-child(1){
    margin-top: -15px !important;
  }
  .baas-solution .nav-tabs .nav-link {
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .baas-solution > div {
    width: 100% !important;
  }
  .baas-solution .nav-tabs .nav-link {
    font-size: 10px;
  }
  .t-top {
    right: 18px;
    bottom: 36px;
  }
  .t-bottom {
    top: -8px;
    right: 18px;
  }
  .custom-design-card {
    margin-top: -25px;
  }
}

@media only screen and (max-width: 1024px) {
  .baas-banner {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .baas-banner .container {
    padding-bottom: 0;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .custom-design-card {
    margin-top: 0px;
  }
  .custom-design-card .info-div {
    width: 80%;
  }
  .custom-design-card .info-div h6 {
    font-size: 16px;
  }
  .custom-design-card .info-div p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .baas-banner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .baas-banner .container {
    padding-bottom: 0;
  }
  .baas-bn-img {
    width: 300px;
  }
  .custom-design-card .info-div {
    width: 100%;
  }
  .custom-design-card {
    width: 100%;
  }
  .container.baas-solution {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .baas-solution img {
    width: 80%;
    margin-top: 1rem;
  }
  .outer-wrapper-circle {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    display: grid !important;
    place-items: center;
    padding: 0 !important;
    min-width: 50px !important;
  }
  .custom-card-container .container:nth-child(2){
    margin-top: 40px;
  }
  .service-table td{
    min-width: 150px !important;
    text-align: center;
  }
  .service-table tr td:first-child{
    text-align: left !important;
  }
  .service-table tr:nth-child(n+2) td:nth-child(n+3){
    display: none !important;
  }
  .service-table tr td.normal-child{
    display: none;
  }
  .service-table tr td.special-child{
    display: block !important;
    border-top-right-radius: 10px !important;
  }
  .rotated-rects .custom-design-card:nth-child(n+5) .info-div{
    margin-top: 0 !important;
  }
  .rotated-rects .custom-design-card:nth-child(n+5) img:nth-child(1){
    margin-top: 0 !important;
  }
  .outer-wrapper-circle{
    width: 45px !important;
    height: 45px !important;
    min-width: 45px !important;
  }
  .baas-solution .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    gap: initial !important;
    justify-content: start;
  }
  .baas-solution .nav-tabs {
    border-bottom: 0px !important;
    margin-bottom: 0px;
  }
  .baas-solution .nav-link{
    border: 2px solid black !important;
    border-bottom: 0px !important;
    border-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }
  .baas-solution .nav-link.active {
    background: black !important;
    margin-bottom: -2px !important;
    border: 2px solid #e4c221 !important;
    border-bottom: 5px black !important;
  }
  .baas-solution .nav-link:hover {
    background: black !important;
    margin-bottom: -2px !important;
    border: 2px solid #e4c221 !important;
    border-bottom: 5px black !important;
  }
}

.baas-solution .nav-link {
  font-weight: 500;
}
@media only screen and (max-width: 400px){
  .download-text-1 span{
    font-size: 10px !important;
  }
  .download-text-2 span{
    font-size: 10px !important;
  }
}