* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Navbar {
  background-image: url("/src/assets/nav-bg.webp");
  width: 100%;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-heading {
  height: 100%;
}

.card1-position {
  position: absolute;
  top: 10%;
  left: 65%;
}

.card2-position {
  position: absolute;
  top: 35%;
  left: 65%;
}

.chip-custom-position {
  position: absolute;
  top: 270px;
  left: 720px;
}

.margin-top-40 {
  margin-top: 150px;
}

.clearfix {
  clear: both;
}

.rectangle {
  position: relative;
}

.para {
  background-color: #f6b83a;
  /* padding-left: 30px;
   padding-right: 30px; */
  text-wrap: wrap;
  padding: 10px 45px 5px 45px;
}

.fs-600 {
  font-weight: 600;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}

.default-gredint {
  background: linear-gradient(270deg, #df8908 10%, #e4c221 100%);
}

.linear-gredint {
  background: linear-gradient(270deg, #df8908 10%, #e4c221 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.small-triangle {
  width: 0;
  height: 0;
  border-left: 39px solid white;
  border-bottom: 22px solid white;
  border-right: 21px solid transparent;
  border-top: 41px solid transparent;
  position: relative;
  left: 57px;
}

.small-right-triangle {
  width: 0;
  height: 0;
  border-left: 39px solid white;
  border-bottom: 22px solid white;
  border-right: 21px solid transparent;
  border-top: 41px solid transparent;
  position: relative;
  left: -117px;
  transform: rotateY(180deg);
}

.solution-btn {
  font-size: 14px;
  background-color: #fff5c5;
  border: none;
  padding: 5px 18px !important;
}

.custom-button {
  background: linear-gradient(
    270deg,
    #e4c221 100%,
    #df8908 10%
  ); /* Original gradient */
  color: white;
  border: none;
  transition: background 0.4s ease-in-out, color 0.4s ease-in-out;
}

.custom-button:hover {
  background: linear-gradient(
    270deg,
    #fb9c0d 10%,
    #e4c221 100%
  ); /* Reversed gradient */
  color: #000000; /* Change text color on hover */
}

.gray-txt {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.8px;
}

.border-white {
  /* border: 2px solid #c3c1c1; */
  padding: 7px;
  /* margin-right: 10px; */
  border-radius: 10px;
}

.pre-brass-anim a {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.pre-brass-anim a::before,
.pre-brass-anim a::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  padding-left: 20px;
  margin-top: 15px;
}
.pre-brass-anim a::before {
  background-color: #ffc107;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}
.pre-brass-anim a::after {
  content: attr(data-replace);
  font-size: 12px;
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #ffc107;
  white-space: nowrap;
  font-weight: 400;
}

.pre-brass-anim a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.pre-brass-anim a:hover::after {
  transform: translate3d(0, 0, 0);
}

.pre-brass-anim a span {
  min-width: 130px;
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.pre-brass-anim a:hover span {
  transform: translate3d(-200%, 0, 0);
}

/* Presentational Styles */

.pre-brass-anim a {
  text-decoration: none;
  color: #18272f;
  font-weight: 700;
  vertical-align: top;
}

.down-icon {
  width: 135px;
  height: auto;
}
/* 
.circle-wrapper {
    position: relative;
}

.circle {
    border-radius: 50%;
    border-color: rgba(228, 194, 33, 1);
    border-style: dotted;
}

.inner {
    width: 250px;
    height: 250px;
    position: absolute;
    top: 100px;
    left:449px;
}


.outer {
    width: 450px;
    height: 450px;
    position: absolute;
    top: -3px;
    left:349px;
}


.dashboard-img {
    left: 51%;
    position: relative;
    top: 5%;
}


.dashboard-menu {
    background-color: rgba(252, 246, 220, 1);
   
    display: inline-block;
    border: 1px rgba(252, 246, 220, 1) pink;
    padding: 10px;
    position: absolute;
    font-size: small;
}

.pill-1 {
    top: 6%;
    left: 8%;
}

.pill-2 {
    top: 30%;
    left: 6%;
}

.pill-3 {
    top: 57%;
    left: -8%;
}

.pill-4 {
    top: 82%;
    left: -10%;
}

.pill-5 {
    top: 103%;
    left: -0%;
}

.pill-6 {
    top: 122%;
    left: 23%;
}

.pill-7 {
    top: 122%;
    left: 55%;
}

.pill-8 {
    top: 103%;
    left: 64%;
}

.pill-9 {
    top: 82%;
    left: 69%;
}

.pill-10 {
    top: 57%;
    left: 78%;
}

.pill-11 {
    top: 30%;
    left: 84%;
}

.pill-12 {
    top: 6%;
    left: 79%;
} */

/* start */

/* Circle Wrapper */
.circle-wrapper {
  position: relative;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
  margin-right: 20px;
}

/* Circles */
.circle {
  border-radius: 50%;
  border: 3px dotted rgba(228, 194, 33, 1);
  position: absolute;
}

.inner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.outer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Dashboard image */
.dashboard-img {
  position: relative;
  margin: 0 auto;
  display: block;
  /* top: 50%; */
  top: 52%;
  transform: translateY(-50%);
}

/* Pills positioning container */
.dashboard-menu-container {
  /* position: relative;
    width: 100%;
    height: 500px; /* Height based on the outer circle */
  /* margin-top: 50px; */

  margin-left: -88px;
  position: relative;
}
.rounded-pill {
  box-shadow: 0px 0px 2.25px 0px #f9b73d;
  box-shadow: 0px 3.37px 3.37px 0px #0000000f;
  margin-top: -10px;
}

/* Pills */
.dashboard-menu {
  background-color: rgba(252, 246, 220, 1);
  border-radius: 30px;
  text-align: center;
  /* font-size: 14px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  white-space: nowrap;
}



/* Responsive adjustments for pills */
@media (max-width: 768px) {
  /* .pill-1, .pill-2, .pill-3, .pill-4, .pill-5, .pill-6,
    .pill-7, .pill-8, .pill-9, .pill-10, .pill-11, .pill-12 {
        position: relative;
        top: auto;
        left: auto;
        margin: 10px 0;
        text-align: center;
        display: block;
    }

    .dashboard-menu-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .inner, .outer {
        display: none; 
    }
    
    .dashboard-img {
        transform: none;
    } */

  .circle-wrapper {
    display: none;
  }
}
/* @media (max-width: 1200px) {
    .lap-img{
        display: none !important;
    }
} */

@media (max-width: 1450px) {
    .circle-wrapper{
        margin-left: 130px !important;
    }
}
@media (max-width: 1200px) {
    .circle-wrapper{
        width: 500px !important;
        margin-left: 0px !important;
    }
    .row.rounded-pills-container{
        justify-content: center !important;
    }
}
/* end */

.baas-solution {
  /* background-image: url("../assets/Prepaid\ Bass\ Solution.svg"); */
  /* width: 100px;
    height: 700px; */
  /* margin-top: 200px; */
  margin-top: 75px;
  /* background-color: black; */
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  overflow: hidden;
}
.home-baas.baas-solution {
  background-image: url("../assets/Prepaid\ Bass\ Solution.svg");
  /* width: 100px;
    height: 700px; */
  /* margin-top: 200px; */
  margin-top: 75px;
  background-color: black;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  overflow: hidden;
}
.rbi-banner {
  padding-top: 37px;
}

.rbi-banner-bg {
  background-image: url("../assets/Mask\ group.png");
  background-color: black;
  color: grey;
  text-overflow: hidden;
  background-repeat: repeat;
}

.color-yellow {
  color: #f6b83a;
}

.rbi-image-res {
  display: none;
}

.rbi-image {
  position: relative;
  left: 64px;
  bottom: 9px;
  height: 115px;
}

.rbi-banner p {
  padding: 20px 90px;
  border-radius: 130px;
  /* height: 71px; */
}

.outer-wrapper-circle {
  border-radius: 50%;
  border: 2.5px solid #f6b83a;
  position: relative;
  left: 14px;
  background-color: rgba(25, 25, 25, 0.9);
  z-index: 99;
}

.outer-wrapper-circle img {
  width: 20px;
  height: 20px;
  margin-top: 0 !important;
}

.color-grey {
  color: grey;
}

.circle-details {
  font-size: 12px;
  font-weight: 400;
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
  padding: 10px 0;
  padding-left: 20px;
  white-space: nowrap;
  background: linear-gradient(
    90deg,
    rgba(86, 83, 83, 0.5),
    rgba(255, 255, 255, 0)
  );
  border-image: linear-gradient(to right, #787878 0%, #000000 100%) 1;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-left: none;
  margin-top: 5px;
}

.child-class {
  font-size: 9px;
}
.contact-us{
  margin-top: 70px !important;
  padding: 70px 0px !important;
}
@media (max-width: 600px) {
  .home-baas.baas-solution{
    margin-top: 20px;
  }
  .contact-us{
    margin-top: 60px !important;
    padding: 60px 0px !important;
  }
  .child-class {
    margin-top: 20px;
    font-size: 10px;
    justify-content: start;
    align-items: center;
  }
  .baas-solution{
    background-position: left !important;
  }
  .pre-brass-anim > div{
    gap: 4px !important;
  }
  .home-baas .container{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .rbi-container p{
    font-size: 10px !important;
    line-height: .4cm;
  }

  .margin-top-40 {
    margin-top: 20px;
  }
.blog-fluid .header h5{
  font-size: 16px;
  font-weight: 500;
}
.banner-container-bt{
  width: 90% !important;
}

.blog-container .cards:nth-child(2){
  display: none;
}
.blog-container .cards:nth-child(3){
  display: none;
}
.blog-container .cards:nth-child(4){
  display: none;
}
  /* .baas-resp {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      } */
}

.text-align-left {
  text-align: left;
}

.iphone-1 {
  position: absolute;
  right: 440px;
  width: 33%;
  top: -16%;
}

.iphone-2 {
  position: absolute;
  top: 65%;
  left: 13%;
  width: 31%;
}

.iphone-3 {
  position: absolute;
  height: 214px;
  top: 36%;
  left: 45%;
}



.cards {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border: 2px solid grey; */
  padding: 20px;
  /* border-radius: 5px; */
}

.cards p {
  color: grey;
  text-align: start;
}

.cards h6 {
  color: #f6b83a;
  text-decoration: dotted;
  text-align: start;
  text-decoration: underline;
  cursor: pointer;
}

.contact-us {
  margin-top: 100px;
  background-image: url("../assets/contact-us-bg.webp");
  /* padding: 160px 160px 160px 80px; */
  padding: 120px 0px;
  background-size: cover;
}

/* */
.contact-left {
  background: linear-gradient(180deg, #fecd44 0%, #feca39 22%, #ffbe00 100%);
}
.contact-circle {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* @media (max-width: 600px) {
   
    .contact-us-left {
        text-align: center;
        background-clip: text;
        justify-content: center;
       
    }

    .contact-us-left > * {
        margin-left: 0px;
    }

  } */

.cards {
  position: relative; /* Ensure card position context */
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.cards {
  border: 2px solid transparent; /* Default to transparent */
  transition: border 0.3s ease-in-out;
}

.cards:hover {
  border: 2px solid #ffc107; /* Yellow border on hover */
  border-radius: 8px;
}

.links {
  margin-left: 192px;
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: rgb(244, 230, 166);
}

.link {
  margin-left: 168px;
}

.link img {
  padding: 5px;
}

.mail-link {
  margin-top: -30px;
}

.form {
  padding: 42px 24px;
  background-color: white;
  border-radius: 10px;
}

.input-field {
  background-color: #f4e6a6 !important;
  color: grey;
  padding: 10px !important;
}

.margin-left-65 {
  margin-left: 65px;
}

.banner-container {
  background-image: url("../assets/contact-us-banner.png");
  height: 199px;
  overflow: hidden;
  margin-top: 100px;
  background-repeat: round;
}
.banner-container-bt {
  background-image: url("../assets/contact-us-banner.png");
  overflow: show;
  background-repeat: no-repeat;
  background-size: cover;
}
.bott-bnr-img img {
  width: 50%;
  margin-top: -76px;
}

/* .banner-container-inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
 } */

.card1-position {
  transition: top 0.5s ease-in-out;
}

.card2-position {
  transition: top 0.5s ease-in-out;
}

.card-container:hover .card1-position {
  top: 5%;
}

.card-container:hover .card2-position {
  top: 40%;
}
.contact-us-left-section {
  margin-left: 30%;
}
.contact-us-left-section > p {
  text-align: left !important;
}
.contact-us-social {
  margin-right: 10%;
}
.text-justify {
  text-align: justify;
}

.pulse {
  --color: #df8908;
  --hover: #e4c221;
}
.pulse:hover,
.pulse:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

.login-btn{
  background: linear-gradient(89.99deg, #E4C221 23.14%, #E4C221 23.14%, #FBB640 99.27%);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@media (max-width: 450px) {
  .contact-us-left-section > h3 {
    font-size: 13px;
    text-align: center !important;
  }

  .contact-us-left-section > p {
    font-size: 8px;
    text-align: center !important;
  }

  .links {
    font-size: 10px;
    margin-left: 0px;
  }
  .rbi-image-res {
    height: auto;
    width: auto;
  }
  .rbi-banner p {
    padding: 20px 20px;
    border-radius: 5px;
  }
  .rbi-image-res {
    margin-top: -12px;
  }
  .contact-us-social {
    margin: 0 auto;
  }
  .link {
    margin-left: 0px;
  }

  .banner-container-bt {
    overflow: show;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .download-info {
    padding-top: 20px;
    margin-left: 150px;
  }
  .download-info-img-box {
    margin-left: 135px;
  }
  .download-info-img {
    width: 100px;
  }
  .bott-bnr-img img {
    width: 40%;
    margin-top: -221px;
  }
  .text-secondary {
    text-align: left;
  }
}

@media (max-width: 769px) {
  .navbar-toggler-icon {
    filter: invert(1) !important;
  }

  .rbi-image {
    display: none;
  }

  .rbi-banner-bg {
    font-size: 11px;
    text-align: start;
  }

  .rbi-banner {
    flex-direction: row;
    align-items: center;
  }

  .rbi-image-res {
    /* margin-top: 13px; */
    display: inline-block;
  }

  .rbi-banner {
    padding-top: 12px;
  }

  .Navbar {
    height: auto;
    padding-bottom: 50px;
  }
  .contact-us-left {
    background: #ffc107;
  }
  .contact-us-left-section {
    margin-left: 0%;
  }
}

@media only screen and (min-width: 1400px){
  .download-banner{
    width: 45%;
  }
}
@media only screen and (max-width: 1400px){
  .download-banner{
    width: 42%;
  }
  .circle-wrapper{
    transform: scale(0.9);
  }
  .dashboard-menu-container .dashboard-menu:nth-child(1){
    left: 12%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(2){
    left: 8%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(3){
    left: -10%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(4){
    left: -14%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(8){
    right: -24%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(9){
    right: -30%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(10){
    right: -23%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(11){
    right: -12%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(12){
    right: -8%;
  }
}
@media only screen and (max-width: 1347px){
  .download-banner{
    width: 45%;
  }
}
@media only screen and (max-width: 1180px){
  .download-banner{
    width: 56%;
  }
}

@media only screen and (max-width: 1200px){
  .dashboard-menu-container .dashboard-menu:nth-child(1){
    left: 15%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(2){
    left: 14%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(3){
    left: -4%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(4){
    left: -8%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(5){
    left: -2%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(8){
    right: -18%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(9){
    right: -22%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(10){
    right: -15%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(11){
    right: -5%;
  }
  .dashboard-menu-container .dashboard-menu:nth-child(12){
    right: -2%;
  }
}

@media only screen and (max-width: 992px){
  .download-banner{
    width: 65%;
  }
}
@media only screen and (max-width: 768px){
  .download-banner{
    width: 100%;
  }
  .pre-brass-anim a::after {
    font-size: 10px;
  }
  .circle-details{
    font-size: 10px;
    padding: 8px 0px !important;
    padding-left: 20px !important;
  }
  .pre-brass-anim a span{
    min-width: 130px;
  }
  .child-class{
    margin-left: -10px;
  }
}

@media only screen and (max-width: 400px){
  .pre-brass-anim a::after {
    font-size: 9px;
  }
  .circle-details{
    font-size: 9px;
    padding: 8px 0px !important;
    padding-left: 20px !important;
  }
  .pre-brass-anim a span{
    min-width: 100px;
  }
  .child-class{
    margin-left: -18px;
  }
}
@media only screen and (min-width: 1500px){
  .banner-container-bt{
    width: 55%;
  }
}
@media only screen and (max-width: 1400px){
  .banner-container-bt{
    width: 70%;
  }
  .banner-container-bt .download-btns{
    margin-left: -60px;
  }
}

@media only screen and (max-width: 1760px){
  .banner-container-bt .download-btns{
    margin-left: -80px;
  }
}
@media only screen and (max-width: 1760px){
  .banner-container-bt{
    width: 60%;
  }
  .banner-container-bt .download-btns{
    margin-left: -80px;
  }
}
@media only screen and (max-width: 1480px){
  .banner-container-bt{
    width: 70%;
  }
  .banner-container-bt .download-btns{
    margin-left: -60px;
  }
}

@media only screen and (max-width: 1280px){
  .banner-container-bt .download-btns{
    margin-left: -80px;
  }
}
@media only screen and (max-width: 1279px){
  .banner-container-bt .download-btns{
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1380px){
  .blog-fluid .header{
    width: 95%;
  }
  .blog-container{
    width: 95%;
  }
  .blog-container .cards p{
    font-size: 12px;
  }
  .blog-container img{
    height: 120px;
  }
  .contact-us .contact-us-container{
    width: 95%;
  }
}