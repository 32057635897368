* {
    margin: 0;
    padding: 0;
}

h1 {
    color: black;
}

.social-ico{
    width: 40px;
    height: auto;
}

