.contact-us-bg {
    background: url("../assets/contect-us-banner.svg");


    background-size: contain; /* Ensures the entire image is visible */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    /* background-position: center; */
    width: 100%;
    height: 100%;
    min-height: 60vh; 
    aspect-ratio: attr(data-aspect-ratio); 

}


.contact-banner {
    background-image: url("../assets/contact-us-yellow-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    width: 100%;
}

.contact-form-wrapper {
    /* margin-top: 250px; */
    margin-top: -50px;
}



@media (max-width : 992px){
    .contact-bnr-img{
        height: 200px !important;
        object-fit: cover !important;
    }
}

@media (max-width : 426px) {
   
    .contact-form-wrapper {
        margin-top: -40px;
    }


    .contact-banner > div > h1 {
        font-size: smaller;
    }

    .contact-banner > div > p {
        font-size: 10px;
    }


    
}