
/* 
.baas-bottom-banner {
    background : url("../assets/baas-bottom-bg.svg");
    background-color: black;
    height: 15em;
    width: 90%;
    margin-top: 50px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
   
} */


.baas-bottom-banner {
    background: url("../assets/baas-bottom-bg.svg") no-repeat center center;
    background-size: cover; /* This ensures the background image scales with the container */
    background-color: black;
    height: auto; /* Allows the height to adjust based on content */
    padding: 20px;
    margin-top: 50px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    overflow: hidden;
}

