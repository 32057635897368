@import url("../App.css");


.login-wrapper {
    padding: 30px;
    background-color: #FEF9EB;
}



@media (max-width : 426px) {
    
    .login {
        padding-top: 0px;
    }

 
}


