.custom-select {
    width: 200px;
    font-size: 12px;
}

.custom-btn{
    border: none; /* Remove border */
    padding: 10px 20px; /* Padding inside the button */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the button inline */
    margin: 4px 2px; /* Margin around the button */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 5px; /* Rounded corners */
    background-color: #FFF4D6 !important;
    background-image: none;
}
.custom-btn.active {
    background-image: linear-gradient(
        to right,
        rgba(228, 194, 33, 1),
        rgba(228, 194, 33, 1),
        rgba(251, 182, 64, 1)
    );
    /* color: white; White text */
    
}
.tnc-accordion .accordion-item{
    border-radius: 8px !important;
    margin-bottom: 8px;
    background-color: #fff !important;
    color: black !important;
    border: 0 !important;
}
.tnc-accordion .accordion-header, .tnc-accordion .accordion-button{
    background-color: #FCDE9E !important;
    border-radius: 8px !important;
    color: #000 !important;
}
.tnc-accordion .accordion-button{
    border-radius: 6px !important;
}

@media only screen and (max-width: 768px){
    .custom-btn{
        font-size: 10px !important;
        font-weight: 600;
    }
    .terms-page ul{
        padding-left: 0;
    }
    .terms-page ol{
        padding-left: 0;
    }
}