

.career-top {
    background-image: url(../assets/career-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


.career-img {
    border-radius: 15px;
    padding: 16px;
    width: 55px;
}


.font-size-13px {
    font-size: 13px;
}
.jobs-section{
  border: 1px solid;
  border-image-source: linear-gradient(89.99deg, #E4C221 23.14%, #E4C221 23.14%, #FBB640 99.27%);
}
.jobs-section .nav-link{
  border-radius: 0px !important;
  transition: .3s;
  text-align: left;
  color: black !important;
  font-weight: 300;
  padding: 10px !important;
  font-size: 14px !important;
}
.jobs-section .nav-link:hover{
  background: transparent !important;
  font-weight: 600 !important;
  border-left: 4px solid #F0BB31 !important;
  padding: 10px !important;
  font-size: 14px !important;
 
}
.jobs-section .nav-link.active{
  background: transparent !important;
  font-weight: 600 !important;
  border-left: 4px solid #F0BB31 !important;
  padding: 10px !important;
  font-size: 14px !important;

}
 /* Custom styles for active tab */
 .nav-pills .nav-link.active {
    background-color: #ffc107; /* Custom background color */
    color: black; /* Custom text color */
    border-radius: 5px; /* Optional: rounded corners */
  }

  /* Custom styles for non-active tabs */
  .nav-pills .nav-link {
    color: #ffc107; /* Custom text color */
  }

  /* Hover effect for non-active tabs */
  .nav-pills .nav-link:hover {
    background-color: #E8F5E9; /* Custom background color on hover */
    color: #ffc107; /* Custom text color on hover */
  }

.modal.show.apply-job{
  display: block !important;
  background: rgba(0, 0, 0, 0.467);
}
.apply-job .modal-content{
  background: #FFFDF8;
}
.apply-job input, .add-new-job-form input {
  background: #FDF4DE;
  font-size: 14px;
  border: 1px solid #F4E6A6 !important;
  font-weight: 400 !important;
}
.apply-job textarea, .add-new-job-form textarea {
  background: #FDF4DE;
  font-size: 14px;
  border: 1px solid #F4E6A6 !important;
}
.apply-job select, .add-new-job-form select {
  background: #FDF4DE;
  font-size: 14px;
  border: 1px solid #F4E6A6 !important;
}
.custom-file-input{
  background: #FDF4DE;
  font-size: 14px;
  border: 1px solid #F4E6A6 !important;
}
.apply-job label{
  font-size: 12px;
  color: gray;
}
.apply-job button, .add-new-job-form button{
  background: linear-gradient(89.99deg, #E4C221 23.14%, #E4C221 23.14%, #FBB640 99.27%);
}

.modal.show.apply-job .modal-dialog{
  max-width: 1000px !important;
}

.add-new-job-btn{
  background: linear-gradient(89.99deg, #E4C221 23.14%, #E4C221 23.14%, #FBB640 99.27%);

}
.skill-dropdown li:hover{
  background: rgba(0, 0, 0, 0.048) !important;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}