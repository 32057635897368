* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
}


.about-us-1 {
    top:34%;
    left:5%;
    width: 200px;
    height: 100px;
}

.about-us-2 {
    top: 68%;
    left: 5%;
    width: 215px;
    height: 100px;
}

.about-us-3 {
    top:5%;
    left:45%;
    width: 128px;
}

.about-us-4 {
    top: 41%;
    left: 73%;
    height: 166px;
}
.yellow-background {
    background-color: #ffc107;
    border-radius: 20px; 
    text-decoration: none;
    color: black; 
}
.download-banner{
    clip-path: polygon(0 0, 100% 0, 93% 100%, 6% 100%);
}
.nav-link{
    font-weight: 200;
}
.navbar-2 li{
    font-size: 16px;
}

@media only screen and (max-width:1530px){
    .container{
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
}
@media only screen and (max-width:1200px){
    .container{
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
    .navbar-2 li{
        font-size: 14px;
    }
    .navbar-2 ul{
        gap: 10px !important;
    }
}

@media only screen and (max-width:1400px){
    .section1 .main-h{
        font-size: 30px;
    }
    .section1 .main-h span{
        font-size: 32px;
    }
}

@media (max-width : 769px) {

    .rbi-image {
        display: none;
    }
    .download-banner{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

}
