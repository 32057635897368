.pp-tabs::-webkit-scrollbar{
 height: 4px;
}
.pp-tabs::-webkit-scrollbar-thumb{
    background-color: rgba(128, 128, 128, 0.185);
    border-radius: 10px !important;

}
.pp-tabs::-webkit-scrollbar-track{
    background-color: transparent;
}

@media only screen and (max-width: 768px){
    .privacy-page ul{
        padding-left: 1.5rem;
    }
    .privacy-page ol{
        padding-left: 1.5rem;
    }
}