

.financial {
    background-image: url("../assets/investor-relation-2.svg");
    background-repeat: no-repeat;
    color: white;
    padding: 10px;
    display: flex;
    /* width: 550px; */
    background-size: cover;
    
}
.whiteboard{
    background-image: url(../assets/curve-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}