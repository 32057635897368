

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
}

.smooth-resize {
  transition: transform 0.3s ease-in-out;
}

.smooth-resize:hover {
  transform: scale(1.4);
  z-index: 999;
}


.box {
  width: 250px;
  height: 250px;
  border: 10px solid black;
}

.accordion-button::after{
  background-image: url(./assets/down.svg) !important;
  transform: scale(0.6) !important;
}