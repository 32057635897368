.corporate-banner-bg{
    background-image: url('../assets/corporate-banner-bg.png');
    background-size: fill;
}

.solution-container  {
    background-image: url('../assets/yellow-Net.png');
    background-size: cover;
}


.background-light-yellow {
    background-color: #FCF5E6;
}


.img-wrapper {
    /* border: 1px solid yellow; */
    border-radius: 50%;
    padding: 13px 12px;
    align-self: center;
}


.font-size-15 {
    font-size: 12px;
 
}


.padding-10 {
    padding: 5px;
}

.corp-card {
    height: 204px;
    margin: 51px;
}


.e-1 {
    left: 12%;
}


.e-2 {
    left: 81%;
}


.e-3 {
    left: 12%;
    top: 75%;
}


.e-4 {
    left: 81%;
    top: 75%;
}


.baas-bottom-banner {
    background : url("../assets/baas-bottom-bg.svg");
    background-color: black;
    height: 15em;
    margin-top: 50px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
   
}

.faq-banner {
    background: url("../assets/baas-faq-banner.svg");
    background-color: black;
}


.accordion-container {
    margin: 0 auto;
    
}

.get-start-btn{
    width: 200px;
}

.icon-width{
  width: 40px;
  height: 40px;
    border: 1px solid #7e7d7d;
    border-radius: 50%;
    object-fit: contain;
    background: #000;
}

.tab-head{
    font-size: 14px;
    font-weight: 600;
    margin: 0 7px;
}

.nav-pills .nav-link:hover {
    background-color: #060606 !important;
    color: #5b5b5b !important;
    font-size: 14px;
    font-weight: 600;    
}


.bg-prepaid-bass-banner{
    background: url("../assets/prepaid-bass-banner-bg.png");
}

  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }
  
  .playbtn:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, .3);
  }

  
  .playbtn:hover .title {
    top: 90px;
  }
  
  .button {
    position: absolute;
    width: 100px;
    left: 29px;
    top: 37px;
    text-align: center;
    opacity: 0;
    transition: opacity .35s ease;
  }
  
  .button a {
    width: 200px;
    padding: 10px;
    text-align: center;
    color: white;
    border: solid 2px white;
    z-index: 1;
    border-radius: 50%;
  }
  
  .playbtn:hover .button {
    opacity: 1;
  }

  .accordion-button {
    background-color: #000000;
    color: #938d8d;
  }

  .accordion-item{
    border: none;
    border-bottom: 1px solid #343434;
    background-color: #000 !important;
    color: white !important;
  }
  
  .accordion-body {
    border-top: none; /* Remove top border from body */
  }
  .accordion-button:not(.collapsed){
    background-color: #060606;
    color: #ffffff;
  }
  .cp-tabs .nav-item button{
    background: #000 !important;
  }
  .cp-tabs .nav-item button.active{
    background: #E4C221 !important;
  }
  .cp-tabs button.active div{
    color: #000 !important;
  }
  .cp-tabs button div {
    color: #8C8C8C !important;
  }

  .cp-tabs .nav-item:hover button{
    background: #E4C221 !important;
    color: #000 !important;
  }
  .cp-tabs .nav-item:hover button div{
    color: #000 !important;
  }
  /* #pills-tabContent div{
    margin-top: 2rem;
  } */
  .cp-tabs{
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
  .cp-tabs::-webkit-scrollbar{
    height: 3px;
  }
  .cp-tabs::-webkit-scrollbar-thumb{
    background: #E4C221 !important;
    border-radius: 4px;
  }
  .cp-tabs::-webkit-scrollbar-track{
    background: transparent !important;
  }
  .cp-tabs li button div{
    height: 100% !important;
    display: grid;
    place-items: center;
    margin-top: 10px;
  }
  .baas-bottom-banner{
    width: 70%;
  }
  .cp-tabs .nav-item button{
    width: max-content;
    margin-right: 5px;
  }

  .btn.btn-outline-warning:hover{
    color:  white !important;
  }
  @media only screen and (max-width: 1400px){
    .cp-tabs .nav-item button{
      width: max-content;
    }
    .cp-tabs li button div{
      font-size: 12px !important;
    }
    .cp-tabs li img{
      width: 25px;
    }
    .cp-tabs li button{
      padding: 5px;
      height: 35px;
    }
    .cp-tabs li button div{
      margin-top: 0;
      display: grid;
      place-items: center;
    }
    .corporate-hero .left-section h1{
      font-size: 25px;
      font-weight: 600;
      line-height: 1cm;
    }
    .corporate-hero .left-section p{
      font-size: 14px;
    }
    .corporate-hero .banner{
      width: 280px;
    }
    .vertical-rect{
      left: 35%;
    }
    .card-sup1{
      left: 15%;
      top: 20px;
    }
    .card-sup2{
      right: 15%;
      top: 40px;
    }
    .card-sup3{
      bottom: -10px;
      right: 30%;
    }
    .card-sup4{
      left: 15%;
      bottom: 10% !important;
    }
    .baas-bottom-banner{
      width: 70%;
    }
    .baas-bottom-banner img{
      width: 250px;
    }
    .icon-width{
      width:25px;
      height: 25px;
    }
  }

  @media only screen and (max-width: 768px){
    .cp-tabs-container{
      width: 100%;
      margin: auto;
      padding: 0 !important;
    }
    .rounded-pill{
      border-radius: 0 !important;
    }
    .cp-tabs li{
      margin-right: 10px !important;
    }
    .cp-tabs.rounded-pill button{
      border-radius: 20px !important;
      margin-right: 5px;
      width: 100%;
      padding-right: 1.5rem;
    }
    .baas-bottom-banner{
      width: 90%;
    }
    .faq-banner .container{
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .faq-banner .accordion h5{
      font-weight: 400 !important;
    }
    .faq-banner .accordion .accordion-header .accordion-button li{
      list-style: none !important;
    }
    .faq-banner .accordion .accordion-header .accordion-button{
      font-weight: 400 !important;
      font-size: 14px !important;
    }
    .corporate-hero .banner{
      width: 240px;
    }
    .faq-banner .container{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .accordion-button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
@media only screen and (max-width: 1200px){
  .cp-tabs .nav-item button{
    margin-right: 5px;
  }
  .card-sup1{
    left: 15%;
    top: 20px;
  }
  .card-sup2{
    right: 10%;
    top: 20px;
  }
  .card-sup3{
    bottom: -10px;
    right: 20%;
  }
  .card-sup4{
    left: 10%;
    bottom: 5% !important;
  }
  .vertical-rect{
    left: 30%;
  }
}
@media only screen and (max-width: 992px){
  .card-sup1{
    left: 10%;
    top: 20px;
  }
  .card-sup2{
    right: 10%;
    top: 20px;
  }
  .card-sup3{
    bottom: -10px;
    right: 20%;
  }
  .card-sup4{
    left: 10%;
    bottom: 0 !important;
  }
  .vertical-rect{
    left: 30%;
  }
}


@media only screen and (max-width: 768px){
  .card-sup1{
    left: 24%;
    top: 20px;
  }
  .crp-4{
    margin-top: 60px;
  }
  .card-sup2{
    right: 18%;
    top: 50px;
  }
  .card-sup3{
    bottom: -10px;
    right: 30%;
  }
  .card-sup4{
    left: 25%;
    bottom: 0 !important;
  }
  .vertical-rect{
    left: 40%;
  }
}
  @media only screen and (max-width: 600px){
    .customer-count{
      left: 5%;
    }
    .card-sup1{
      left: 5%;
      top: 20px;
    }
    .card-sup2{
      right: 15%;
      top: 25px;
    }
    .card-sup3{
      bottom: -10px;
      right: 20%;
    }
    .card-sup4{
      left: 10%;
      bottom: -2% !important;
    }
  }
  .img-box:hover{
    border: 1px solid #E4C221;
    border-radius: 4px;
  }