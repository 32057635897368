* {
  font-family: "Poppins";
}

.clear::after {
  display: block;
  clear: both;
  content: "";
}

.teams-bg {
  background-image: url(../assets/dots-bg.svg);
  background-repeat: repeat;
  background-size: contain;
}
.wrapper-data {
  border: 1px solid #ffc107;
  padding: 5px;
}

.wrapper-data > p {
  background-color: #ffc107;
  padding: 10px;
  border-top-right-radius: 26px;
  border-left: 7px solid black;
  margin-bottom: 0px;
}

.person-card {
  border: 1px solid #ffc107;
  border-radius: 12px;
}

.img-wrapper {
  position: relative;
}
.senior-team .img-wrapper {
  position: absolute !important;
}

.person-info > p {
  padding: 5px;
  margin: 0;
}

.img {
  border-radius: 50%;
  position: absolute;
  top: 22%;
  left: 67px;
}

.person-exp {
  background-color: lightsalmon;
}

.person-exp-ad {
  background-color: white;
}

.team > img {
  left: 30%;
}

.about-bg {
  background-image: url("../assets/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-us-heading {
  width: 60%;
  line-height: 1cm;
}

.about-rect-imgs .rect-2 {
  left: 52%;
  top: 20px;
}

.about-2-fluid {
  background-image: url("../assets/yellow-Net.png");
  background-size: cover;
}
.team-section {
  width: 80%;
  flex-wrap: wrap;
}
.senior-team{
  flex-wrap: wrap;
}


.person-card {
  transition: 0.1s;
  cursor: pointer;
}

.person-card:hover .img-wrapper-circle {
  animation: spin 2s ease;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.marquee {
  display: inline-flex;
  animation: marquee 20s linear infinite;
}

.marquee img {
  width: 150px;
  margin: 0 10px;
  border-radius: 10px;
}

/* Duplicate content for infinite effect */
.marquee .marquee-clone {
  display: inline-flex;
}
.marquee-container:hover .marquee {
  animation-play-state: paused;
}

/* Keyframes for animation */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media only screen and (max-width: 1400px) {
  .about-us-heading {
    width: 70%;
  }
  .about-rect-imgs .rect-2 {
    left: 65%;
    top: 20px;
  }
  .about-fluid {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .team-section {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .about-us-heading {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .about-rect-imgs .rect-2 {
    left: 55%;
  }
  .person-card{
    min-width: 240px !important;
  }
  .team-section::-webkit-scrollbar{
    display: none;
  }
  .senior-team::-webkit-scrollbar{
    display: none;
  }
  .container.journey-container{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .baas-bottom-banner{
    height: 200px !important;
  }
  .team-section, .senior-team{
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 500px) {
  .about-rect-imgs .rect-2 {
    left: 70%;
    top: 20px;
  }
  .about-rect-imgs .rect-1 {
    top: 60px;
  }
  .about-us-3 {
    height: 250px !important;
  }
}
